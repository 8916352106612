import dracula from "@theme-ui/prism/presets/dracula";

const theme = {
  breakpoints: ["520px", "770px", "825px", '900px', "1000px", "1120px"],
  responsive: {
    small: 768,
    medium: 800,
    mediumLarge: 1000,
    large: 1120,
  },
  sizes: {
    container: "1200px",
  },
  zIndices: [1, 10, 20, 100, 1000, 1030, 2147483643, 2],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  layout: {
    container: {
      px: ["16px", "18px", "18px", "18px", "0"],
    },
    containerSecondary: {
      maxWidth: "1200px",
      px: "16px",
    },
  },
  fonts: {
    fontSource: "'Source Sans Pro', Verdana, sans‑serif",
    heading: "Montserrat, 'Open Sans', Verdana, sans‑serif",
    monospace: '"Roboto Mono", monospace',
    body: "Source Sans Pro, sans-serif",
    sourceSans: "Source Sans Pro, sans-serif",
  },
  fontSizes: [10, 12, 14, 16, 18, 20, 24, 32, 48, 56, 64, 96],
  fontWeights: {
    body: 400,
    heading: 400,
    medium: 500,
    lessBold: 600,
    bold: 700,
    extraBold: 800,
  },
  lineHeights: {
    body: 1.6,
    heading: 1.25,
  },
  letterSpacings: {
    body: "0",
  },
  colors: {
    text: "#0F2A3F",
    white: "#ffffff",
    primary: "#1a73e8",
    secondary: "#9c27b0",
    muted: "#f1f3f4",
    pink: "#FD5383",
    purple: "#8884FF",
    navy: "#143556",
    info: "#deebff",
    // Color Schema For V2 
    orangeV2: '#FF6310',
    orangeV2Accent: '#a03e0a',
    coalBlackV2: '#404041',
    nightBlackV2: '#262626',
    midNightBlue: '#272763',
    royalBlue: '#223F99',
    turqBlue: '#4DC0B4',
    leafGreen: '#B6C647',
    goldYellow: '#F3C62D',
    promePink: '#E2226B',
    levenderPurple: '#BC87E6',
    lightGrey: '#F4F4F7'
  },
  borders: {
    default: "1px solid #0F2A3F",
    card: {
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
      borderRadius: "15px",
      overflow: "hidden",
    },
    heroVideoBorder: {
      border: "2px solid #404041",
      borderRadius: "10px",
    }
  },
  forms: {
    label: {
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "6px",
    },
    input: {
      fontFamily: "body",
      backgroundColor: '#fff',
      borderRadius: '30px',
      minWidth: ['250px', '200px', null, '250px'],
      outline: 'none',
      minHeight: '45px',
      border: '1px solid #404041',
      '&:focus': {
        border: '1px solid #FF6310'
      }
    },
    select:{
      fontFamily: "body",
      backgroundColor: '#fff',
      borderRadius: '30px',
      minWidth: ['250px', '200px', null, '250px'],
      outline: 'none',
      border: '1px solid #404041',
      '&:focus': {
        border: '1px solid #FF6310'
      }
    },
    option: {
      '&:checked': {
        backgroundColor: "black"
      }
    }
  },
  links: {
    default: {
      fontFamily: "body",
      lineHeight: ["24px", "24px", "29px"],
      fontSize: ["16px", "16px", "18px"],
      letterSpacing: "0",
      textDecoration: "underline",
      cursor: "pointer",
    },
    green: {
      fontFamily: "body",
      color: "#00BFA5",
      lineHeight: ["24px", "24px", "29px"],
      fontSize: ["16px", "16px", "18px"],
      letterSpacing: "0",
      cursor: "pointer",
      fontWeight: "bold",
    },
    pink: {
      fontFamily: "body",
      color: "#FD5383",
      lineHeight: ["24px", "24px", "29px"],
      fontSize: ["16px", "16px", "18px"],
      letterSpacing: "0",
      cursor: "pointer",
      fontWeight: "bold",
    },
    purple: {
      fontFamily: "body",
      color: "#8884FF",
      lineHeight: ["24px", "24px", "29px"],
      fontSize: ["16px", "16px", "18px"],
      letterSpacing: "0",
      cursor: "pointer",
      fontWeight: "bold",
    },
  },
  buttons: {
    primary: {
      color: "white",
      bg: "pink",
      fontSize: "18px",
      cursor: "pointer",
      borderRadius: "40px",
      padding: "12px 28px",
      fontWeight: "extraBold",
      letterSpacing: "0",
    },
    primaryV2: {
      color: "white",
      bg: "orangeV2",
      fontSize: "18px",
      cursor: "pointer",
      borderRadius: "30px",
      fontWeight: 500,
      letterSpacing: "0",
      textAlign: 'center',
      height: '40px',
      fontFamily: 'heading',
      '&:hover': {
        backgroundColor: '#DE550A'
      },
      '&:focus': {
        backgroundColor: '#FF6310'
      },
      '&:disabled': {
        color: '##727680',
        backgroundColor: '#D1D3D7'
      }
    },
    primaryAltV2: {
      color: "orangeV2",
      bg: "transparent",
      fontSize: "18px",
      cursor: "pointer",
      border: '1px solid #FF6310',
      borderRadius: "30px",
      fontWeight: 500,
      letterSpacing: "0",
      textAlign: 'center',
      height: '40px',
      marginInline: '10px',
      fontFamily: 'heading',
      '&:hover': {
        color: '#DE550A',
        border: '1px solid #DE550A',
      },
      '&:focus': {
        color: '#DE550A',
        border: '1px solid #DE550A',
      },
      '&:disabled': {
        color: '##727680',
        backgroundColor: '#D1D3D7'
      }
    },
    primaryLarge: {
      color: "white",
      bg: "pink",
      fontSize: "24px",
      cursor: "pointer",
      borderRadius: "40px",
      padding: "12px 28px",
      fontWeight: "bold",
      letterSpacing: "0",
    },
    secondary: {
      color: "white",
      bg: "purple",
      fontSize: "12px",
      cursor: "pointer",
      borderRadius: "40px",
      padding: "12px 28px",
      fontWeight: "extraBold",
      letterSpacing: "0",
    },
    secondaryV2: {
      color: "text",
      bg: "transparent",
      fontSize: "20px",
      cursor: "pointer",
      padding: "12px 3px",
      fontWeight: "600",
      letterSpacing: "0",
    },
    link: {
      textDecoration: "none",
      color: "text",
      fontWeight: 700,
      fontFamily: "heading",
      display: "flex",
      alignItems: "center",
      bg: "transparent",
    },
    linkUnderline: {
      textDecoration: "underline",
      color: "orangeV2",
      fontWeight: 700,
      fontFamily: "heading",
      display: "flex",
      alignItems: "center",
      bg: "transparent",
    },
    linkLarge: {
      textDecoration: "none",
      color: "text",
      fontWeight: 700,
      fontSize: '30px',
      fontFamily: "heading",
      display: "flex",
      alignItems: "center",
      bg: "transparent",
    },
    linkV2: {
      color: '#f8f8f8',
      fontWeight: 500,
      fontFamily: "heading",
      display: "flex",
      alignItems: "left",
      bg: "transparent",
      "&:hover": {
        color: '#A03E0A',
        // fontStyle: 'italic',
        textDecoration: 'none'
      },
      "&:visited": {
        color: "orangeV2",
        fontWeigth: "bold",
      },
      "&:active": {
        color: 'turqBlue'
      }
    },
    linkPink: {
      textDecoration: "none",
      color: "pink",
      fontWeight: 700,
      fontFamily: "heading",
      display: "flex",
      alignItems: "center",
      bg: "transparent",
    },
    linkPrimary: {
      textDecoration: "none",
      color: "#FF6310",
      fontWeight: 700,
      fontFamily: "heading",
      display: "flex",
      alignItems: "center",
      bg: "transparent",
    },
    pinkHover: {
      textDecoration: "none",
      color: "text",
      fontWeight: 700,
      fontFamily: "heading",
      display: "flex",
      alignItems: "center",
      bg: "transparent",
      '&:hover': {
        color: '#FF6310'
      }
    },
  },
  badges: {
    gray: {
      background: "#FAFAFA",
      color: "#262626",
      border: "1px solid #D9D9D9",
      borderRadius: "16px",
      fontSize: "12px",
      padding: "0 8px",
      lineHeight: "24px",
      fontWeight: "body",
    },
    green: {
      background: "#F6FFED",
      color: "#389E0D",
      border: "1px solid #B7EB8F",
      borderRadius: "16px",
      fontSize: "12px",
      padding: "0 8px",
      lineHeight: "24px",
      fontWeight: "body",
    },
  },
  blog: {
    h1: {
      fontFamily: "heading",
      lineHeight: 1.25,
      fontSize: [32, 48, 56],
      fontWeight: 'bold'
    },
    h2: {
      fontFamily: "heading",
      lineHeight: 1.25,
      fontSize: [28, 32],
      fontWeight: 'bold',
      marginTop: '36px',
      marginBottom: '20px',
      description: {
        fontSize: [28, 32],
        fontWeight: 'medium',
        color: '#707070',
        marginTop: '20px',
      }
    },
    h3: {
      fontFamily: "heading",
      lineHeight: 1.4,
      fontSize: [24, 28],
      fontWeight: 'lessBold',
      marginTop: '36px',
      marginBottom: '20px',
      letterSpacing: "0.02em",
    },
    h4: {
      fontFamily: "heading",
      lineHeight: 1.5,
      fontSize: [20, 24],
      fontWeight:'lessBold',
      marginTop: '36px',
      marginBottom: '20px',
    },
    h5: {
      fontFamily: "heading",
      lineHeight: 1.5,
      fontSize: [18, 20],
      fontWeight: 'lessBold',
      marginTop: '36px',
      marginBottom: '20px',
      author: {
        fontSize: 24,
        fontWeight: 'lessBold',
      }
    },
    p: {
      fontFamily: "fontSource",
      lineHeight: '1.5 !important',
      fontSize: [16, 16, 16, 16, 18, 18, 18],
      marginBottom: 20,
    },
    li: {
      fontSize: 18,
      lineHeight: 1.8,
      wordWrap: "break-word",
      marginLeft: "8px",
    },
    link: {
      color: 'orangeV2',
      textDecoration: 'underline',
      '&:hover': {
        color: 'orangeV2Accent',
        textDecoration: 'none',
      },
      '&:visited': {
        color: 'orangeV2',
      },
      '&:active': {
        color: 'turqBlue',
      },
    },
  },
  styles: {
    root: {
      body: {
        color: "rgba(0, 0, 0, 0.65)",
        fontSize: "14px",
        fontVariant: "tabular-nums",
        lineHeight: 1.5,
        backgroundColor: "#fff"
      },
      "--toastify-z-index": 9999,
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      h1: {
        fontFamily: "heading",
        lineHeight: ['1.4em', "1.25em"],
        fontSize: ["48px", '56px'],
        fontWeight:'700 !important'
      },
      h2: {
        fontFamily: "heading",
        lineHeight: "1.4em",
        fontSize: ["32px", '48px'],
        fontWeight:'bold'
      },
      h3: {
        fontFamily: "heading",
        lineHeight: "1.5em",
        fontSize: ["24px", "32px"],
        fontWeight:'bold'
      },
      h4: {
        fontFamily: "heading",
        lineHeight: ['1.5em', "1.4em"],
        fontSize: ["20px", "28px"],
        fontWeight:'bold'
      },
      h5: {
        fontFamily: "heading",
        lineHeight: "1.5em",
        fontSize: ['18px', "24px"],
        fontWeight:600
      },
      h6: {
        fontFamily: "heading",
        lineHeight: "1.5em",
        fontSize: ['16px', '20px'],
        fontWeight:600
      },
      b:{
        fontWeight:700
      },
      p: {
        fontFamily: "fontSource",
        lineHeight: '1.5em',
        fontSize: '18px',
        letterSpacing: "0",
        color:'#404041'
      },
      psm: {
        fontFamily: "body",
        lineHeight: "1.5em",
        fontSize: '14px'
      },
      plg: {
        fontFamily: "body",
        lineHeight: "1.5",
        fontSize: '24px'
      },
      pre: {
        fontFamily: "monospace",
        overflowX: "auto",
        code: {
          color: "inherit",
        },
      },
      li: {
        fontSize: '12px',
        lineHeight: '1.8'
      },
      blockquote: {
        fontStyle: "italic",
        paddingLeft: "8px",
      },
      blockquotev2: {
        fontFamily: 'heading',
        fontSize: '24px',
        fontStyle: 'italic',
        fontWeigth: 'light',
        lineHeight: '1.8'
      },
      code: {
        fontFamily: "monospace",
        fontSize: "inherit",
        backgroundColor: "rgba(9,30,66,0.08)",
        color: "#fff",
      },
      table: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: 0,
      },
      th: {
        textAlign: "left",
        borderBottomStyle: "solid",
      },
      td: {
        textAlign: "left",
        borderBottomStyle: "solid",
      },
      img: {
        maxWidth: "100%",
      },
    },
    code: {
      ...dracula,
    },
    pre: {
      px: 2,
    },
    hrLight: {
      color: "#f0f0f0",
    },
    spinner: {
      color: "#00BFA5",
    },
  },
  navLink: {
    mx: 3,
    textDecoration: "none",
    color: "text",
    fontWeight: 700,
    fontFamily: "heading",
    display: "flex",
    alignItems: "center",
    my: "10px",
  },
  navList: {
    display: "flex",
    flexDirection: "column",
  },
  mobileNavList: {
    fontSize: "30px",
  },
  collapsibleNavListItem: {
    listStyle: "none",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "18px",
    fontWeight: 700,
    color: "text",
  },
  navListItem: {
    listStyle: "none",
    display: "flex",
    alignItems: "left",
  },
  footerNavListWrapper: {
    flex: 1,
  },
  footerNavList: {
    margin: 0,
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  footerNavListItem: {
    my: "8px",
    color: "#f8f8f8",
    fontSize: "14px",
  },
  text: {
    default: {
      fontFamily: "heading",
      letterSpacing: "0",
    },
    bold: {
      fontWeight: "bold",
    },
    lessBold: {
      fontWeight: "lessBold",
    },
    extraBold: {
      fontWeight: "extraBold",
    },
    paragraph: {
      fontFamily: "body",
      lineHeight: ["24px", "24px", "29px"],
      fontSize: ["14px", "14px", "16px"],
      letterSpacing: "0",
    },
    Paragraph: {
      fontFamily: "Source sans pro",
      lineHeight: ["1.5"],
      fontSize: ["18px"],
      letterSpacing: "0",
    },
    paragraphSmall: {
      fontFamily: "body",
      fontSize: ["12px", "12px", "14px"],
      letterSpacing: "0",
    },
    stats: {
      fontFamily: "heading",
      fontWeight: 600,
      lineHeight: "80%",
      letterSpacing: "0",
    },
  },
};

export default theme;